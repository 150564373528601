body {
  font-family: 'Roboto', sans-serif; /* Apply Roboto font */
  background-color: #ffffff; /* White background */
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-header {
  background-color: transparent;
  color: #004da2; /* TechorTree blue */
  padding: 20px;
  position: relative;
}

.logo-container {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.logo-preview {
  width: 100px;
  height: auto;
  border-radius: 5px;
}

.spacer {
  height: 60px; /* Adjust the height for desired space */
}

.welcome-message {
  margin-top: 60px; /* Adjust the margin-top as needed */
}

.date-time {
  font-size: 16px;
}
